import { merge } from "rxjs";
import produce from "immer";
import createStore from "./store/create-store";
import { environments$ } from "./environments"; // TODO change name

export interface Package {
  name: string;
  version: string;
  globalProperty: string;
  loadDefault: boolean;
  loading?: boolean;
}

interface Environment {
  packages: { [globalProperty: string]: Package };
}

export interface SavedEnvironment extends Environment {
  name: string;
  lastModified: number;
}

export interface CurrentEnvironment extends Environment {
  origin: number | null;
  modified: boolean;
}

export interface SavedEnvironments {
  [key: string]: SavedEnvironment;
}

export interface Environments {
  current: CurrentEnvironment;
  saved: SavedEnvironments;
}

export type State = {
  environments: Environments;
  warning: string | null;
};

export const store$ = merge(environments$).pipe(
  createStore<State>({
    warning: null,
    environments: {
      current: { packages: {}, origin: null, modified: true },
      saved: {},
    },
  })
);

export function updateState(func: (s: State) => void) {
  return (state: State) => produce(state, (draft) => func(draft));
}
