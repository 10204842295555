/*eslint-disable no-eval */
import { merge, of, NEVER } from "rxjs";
import { tap, map, switchMapTo } from "rxjs/operators";
import {
  addDependency$,
  dependencyAdded$,
  dependencyAborted$,
  removeDependency$,
  clearCurrent$,
  saveCurrent$,
  mergeEnvironment$,
  deleteEnvironment$,
} from "./intents";
import {
  Package,
  SavedEnvironment,
  SavedEnvironments,
  updateState,
} from "./store";

type PackageBackup = { [key: string]: unknown };

const packageInjectedSubject$ = "___packageInjectedSubject$";
const packageAbortedSubject$ = "___packageAbortedSubject$";
const packageBackup: PackageBackup = {};

(window as any)[packageInjectedSubject$] = dependencyAdded$;
(window as any)[packageAbortedSubject$] = dependencyAborted$;

export const environments$ = merge(
  of(getSavedEnvironments()).pipe(
    map((savedEnvironments) =>
      updateState((draft) => {
        draft.environments.saved = savedEnvironments;
      })
    )
  ),
  addDependency$.pipe(
    tap(importPackage),
    map((injectedPackage) =>
      updateState((draft) => {
        const { packages } = draft.environments.current;
        // if injecting for the first time, backup the original value
        // this should be tapped
        if (!packages[injectedPackage.globalProperty]) {
          packageBackup[injectedPackage.globalProperty] = (window as any)[
            injectedPackage.globalProperty
          ];
        }
        // /tapped
        packages[injectedPackage.globalProperty] = {
          ...injectedPackage,
          loading: true,
        };
      })
    )
  ),
  removeDependency$.pipe(
    tap(
      (globalProperty) =>
        ((window as any)[globalProperty] = packageBackup[globalProperty])
    ), // Restore the backed-up original value
    map((globalProperty) =>
      updateState((draft) => {
        delete draft.environments.current.packages[globalProperty];
      })
    )
  ),
  dependencyAdded$.pipe(
    map((injectedPackage) =>
      updateState((draft) => {
        const { current } = draft.environments;
        current.packages[injectedPackage.globalProperty].loading = false;
        current.modified = true;
      })
    )
  ),
  dependencyAborted$.pipe(
    map((abortedPackage) =>
      updateState((draft) => {
        delete draft.environments.current.packages[abortedPackage];
      })
    )
  ),
  clearCurrent$.pipe(
    tap(() => window.location.reload()),
    switchMapTo(NEVER)
  ),
  saveCurrent$.pipe(
    map((saveName) =>
      updateState((draft) => {
        // this should be tapped
        saveEnvironment(saveName, {
          packages: draft.environments.current.packages,
          name: saveName,
          lastModified: Date.now(),
        });
        // /tapped
        draft.environments.saved = getSavedEnvironments();
      })
    )
  ),
  mergeEnvironment$.pipe(
    map((envName) =>
      updateState((draft) => {
        // this should be tapped and maybe expressed better with rxjs
        const savedEnvironments = getSavedEnvironments();
        const toLoad = savedEnvironments[envName];
        Object.keys(toLoad.packages).forEach((key) => {
          const packageToLoad = toLoad.packages[key];
          setTimeout(() => addDependency$.next(packageToLoad));
        });
        // /tapped
      })
    )
  ),
  deleteEnvironment$.pipe(
    tap(deleteEnvironment),
    map(() =>
      updateState((draft) => {
        draft.environments.saved = getSavedEnvironments();
      })
    )
  )
);

function deleteEnvironment(name: string) {
  const savedEnvironments = getSavedEnvironments();
  delete savedEnvironments[name];
  saveEnvironments(savedEnvironments);
}

function saveEnvironment(name: string, newEnv: SavedEnvironment) {
  const savedEnvironments = getSavedEnvironments();
  savedEnvironments[name] = newEnv;
  saveEnvironments(savedEnvironments);
}

function getSavedEnvironments() {
  const savedEnvironmentsSerialised = localStorage.getItem("savedEnvironments");
  const savedEnvironments: SavedEnvironments = savedEnvironmentsSerialised
    ? JSON.parse(savedEnvironmentsSerialised)
    : {};
  return savedEnvironments;
}

function saveEnvironments(newEnvironments: SavedEnvironments) {
  localStorage.setItem("savedEnvironments", JSON.stringify(newEnvironments));
}

function importPackage({
  name,
  version,
  globalProperty,
  loadDefault,
}: Package) {
  eval(`
  async function run() {
    try {
      const p = await import("https://jspm.dev/${name}@${version}");
      window["${globalProperty}"] = p${loadDefault ? ".default" : ""};
      console.log("${name}@${version} has been loaded → window.${globalProperty}")
      window["${packageInjectedSubject$}"].next({name: "${name}", version: "${version}", globalProperty: "${globalProperty}", loadDefault: ${loadDefault}})
    }
    catch (e) {
      console.error('Error while loading', e)
      window["${packageAbortedSubject$}"].next("${globalProperty}")
    }
  }
  run()
  `);
}
