import React from "react";
import { Subject } from "rxjs";
import { share, map, tap } from "rxjs/operators";
import { render } from "react-dom";
import { State } from "./store";
import App from "./App";

const root = document.getElementById("root");
const render$ = new Subject<State>();

render$.pipe(
  share(),
  tap(() => console.log("Render state"))
);

render$.pipe(map(toView)).subscribe((jsx) => render(jsx, root));

export default render$;

function toView(state: State) {
  return (
    <React.StrictMode>
      <App state={state} />
    </React.StrictMode>
  );
}
