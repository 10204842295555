import React from "react";
import { NPM } from "./Icons";
import style from "./NPMLink.module.css";

export default function NPMLink({
  name,
  className,
}: {
  name: string;
  className?: string;
}) {
  return (
    <a
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      href={`https://www.npmjs.com/package/${name}`}
    >
      <NPM className={style.Icon} />
    </a>
  );
}
