import React from "react";
import Header from "./components/Header";
import Warning from "./components/Warning";
import Search from "./components/Search";
import Environments from "./components/Environments";
import { State } from "./store";
import style from "./App.module.css";

interface AppProps {
  state: State;
}

function App({ state }: AppProps) {
  return (
    <div>
      <Warning msg={state.warning} />
      <Header />
      <div className={style.App}>
        <Search className={style.Search} />
        <Environments
          className={style.Environments}
          envs={state.environments}
        />
      </div>
    </div>
  );
}

export default App;
