import React, { useState, useRef } from "react";
import classNames from "classnames";
import Package from "./Package";
import { CloseCircle } from "./Icons";
import debounce from "lodash.debounce";
import { addDependency$ } from "../intents";
import { search, AlgoliaPackage } from "../algolia";
import style from "./Search.module.css";

interface SearchProps {
  className?: string;
}

export default function Search({ className }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [packageList, setPackageList] = useState<AlgoliaPackage[]>([]);
  const queryTerm = useRef(
    debounce(async (term: string) => {
      const res = await search(term);
      const hits = res.hits;
      setPackageList(hits);
    }, 500)
  ).current;

  return (
    <div
      className={classNames(style.Search, className, {
        [style.Closed]: !packageList.length,
      })}
    >
      <div className={style.SearchContainer}>
        <input
          className={style.SearchInput}
          onChange={(e) => {
            const term = e.target.value;
            setSearchTerm(term);
            queryTerm(term);
          }}
          value={searchTerm}
          placeholder="Search for a package..."
        ></input>
        <button
          onClick={() => {
            setSearchTerm("");
            setPackageList([]);
          }}
          disabled={!searchTerm}
          className={style.SearchCancel}
        >
          <CloseCircle />
        </button>
      </div>

      {packageList.length ? (
        <ul className={style.Packages}>
          {packageList.map((p) => (
            <Package
              onAddPackage={(name, version, globalProperty, loadDefault) => {
                setSearchTerm("");
                setPackageList([]);
                addDependency$.next({
                  name,
                  version,
                  globalProperty,
                  loadDefault,
                });
              }}
              key={p.objectID}
              {...p}
            />
          ))}
        </ul>
      ) : null}
    </div>
  );
}
