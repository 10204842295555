import React from "react";
import style from "./Header.module.css";

export default function Header() {
  return (
    <div className={style.Header}>
      <p className={style.Logo}>ENV.ROCKS</p>
    </div>
  );
}
