import { Subject } from "rxjs";
import { Package } from "./store";

export const addDependency$ = new Subject<Package>();
export const removeDependency$ = new Subject<string>();
export const dependencyAdded$ = new Subject<Package>();
export const dependencyAborted$ = new Subject<string>();
export const clearCurrent$ = new Subject();
export const saveCurrent$ = new Subject<string>();
export const mergeEnvironment$ = new Subject<string>();
export const deleteEnvironment$ = new Subject<string>();
