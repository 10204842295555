import React from "react";
import classNames from "classnames";
import { Environments as EnvironmentListProps } from "../store";
import {
  clearCurrent$,
  saveCurrent$,
  mergeEnvironment$,
  deleteEnvironment$,
} from "../intents";
import Environment from "./Environment";
import { Save, Bin, Merge } from "./Icons";
import style from "./Environments.module.css";

interface EnvironmentsProps {
  className?: string;
  envs: EnvironmentListProps;
}

export default function Environments({ envs, className }: EnvironmentsProps) {
  const { current, saved } = envs;
  const currentPackageKeys = Object.keys(current.packages);
  const savedEnvironmentsKeys = Object.keys(saved);
  return (
    <div className={classNames(className)}>
      {currentPackageKeys.length ? (
        <div className={style.Wrapper}>
          <p className={style.Title}>Current environment</p>
          <div className={style.Environment}>
            <Environment className={style.TableWrapper} env={current} />
            <div className={style.Controls}>
              <button
                onClick={() => {
                  const name = window.prompt("Save the current environment as");
                  if (name) saveCurrent$.next(name);
                }}
              >
                <Save className={style.Icon} /> SAVE
              </button>
              <button onClick={() => clearCurrent$.next()}>
                <Bin className={style.Icon} /> CLEAR
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {savedEnvironmentsKeys.length ? (
        <div className={style.Wrapper}>
          <p
            className={style.Title}
          >{`Saved environments (${savedEnvironmentsKeys.length})`}</p>
          {savedEnvironmentsKeys.map((key) => {
            const savedEnv = saved[key];
            return (
              <div key={key}>
                <p className={style.Name}>{savedEnv.name}</p>
                <div className={style.Environment}>
                  <Environment className={style.TableWrapper} env={savedEnv} />
                  <div className={style.Controls}>
                    <button
                      onClick={() => mergeEnvironment$.next(savedEnv.name)}
                    >
                      <Merge className={style.Icon} /> MERGE
                    </button>
                    <button
                      onClick={() => {
                        window.confirm(
                          `Are you sure you want to delete environment "${savedEnv.name}"?`
                        ) && deleteEnvironment$.next(savedEnv.name);
                      }}
                    >
                      <Bin className={style.Icon} /> DELETE
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
