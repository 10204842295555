import React, { useState } from "react";
import semver from "semver";
import { camelCase } from "camel-case";
import ReactMarkdown from "react-markdown";
import { AlgoliaPackage } from "../algolia";
import NPMLink from "./NPMLink";
import { Plus } from "./Icons";

import style from "./Package.module.css";

type PackageProps = AlgoliaPackage & {
  onAddPackage: (
    name: string,
    packageVersion: string,
    injectName: string,
    injectDefault: boolean
  ) => void;
};

export default function Package({
  name,
  description,
  versions,
  version,
  onAddPackage,
}: PackageProps) {
  const [scope, pkgName] = name.split("/");
  const [packageVersion, setPackageVersion] = useState(version);
  const [injectName, setInjectName] = useState(camelCase(pkgName ?? scope));
  const [injectDefault, setInjectDefault] = useState(true);
  const options = Object.keys(versions)
    .sort(semverSort)
    .map((v) => ({
      key: v,
      label: v === version ? `${v} - default` : v,
    }));

  return (
    <li key={name}>
      <div className={style.Package}>
        <div className={style.TitleRow}>
          <div>
            <span className={style.Name}>{name}</span>
            <select
              id={`injected-package-${name}`}
              value={options.find((el) => el.key === packageVersion)?.key}
              onChange={(e) => {
                setPackageVersion(e.target.value);
              }}
            >
              {options.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <NPMLink name={name} />
          </div>
        </div>
        <div>
          {description ? (
            <ReactMarkdown source={description} />
          ) : (
            <p>No description</p>
          )}
        </div>
        <div className={style.Inputs}>
          <div>
            <label htmlFor={`injected-property-${name}`}>Global property</label>
            <input
              id={`injected-property-${name}`}
              onChange={(e) => setInjectName(e.target.value)}
              value={injectName}
            ></input>
          </div>
          <div>
            <label htmlFor={`injected-default-${name}`}>Default</label>
            <input
              checked={injectDefault}
              onChange={(e) => setInjectDefault(e.target.checked)}
              id={`injected-default-${name}`}
              type="checkbox"
            ></input>
          </div>
        </div>
        <button
          onClick={() => {
            onAddPackage &&
              onAddPackage(name, packageVersion, injectName, injectDefault);
          }}
        >
          <Plus className={style.Plus} />
          ADD
        </button>
      </div>
    </li>
  );
}

function semverSort(a: string, b: string) {
  try {
    return semver.gt(a, b) ? -1 : 1;
  } catch (e) {
    return -1;
  }
}
