import React from "react";
import { CurrentEnvironment, SavedEnvironment } from "../store";
import { removeDependency$ } from "../intents";
import { Remove } from "./Icons";
import NPMLink from "./NPMLink";
import style from "./Environment.module.css";

interface EnvironmentProps {
  env: CurrentEnvironment | SavedEnvironment;
  className?: string;
}

export default function Environment({ env, className }: EnvironmentProps) {
  const isSaved = "name" in env;
  const { packages } = env;
  const packageKeys = Object.keys(env.packages);
  return (
    <div className={className}>
      <table className={style.PackageList}>
        <thead>
          <tr>
            <th>Global</th>
            <th>Package</th>
            <th>Default</th>
            <th>Registry</th>
          </tr>
        </thead>
        <tbody>
          {packageKeys.map((key) => {
            const p = packages[key];
            const { loading, globalProperty, version, name, loadDefault } = p;
            return (
              <tr className={style.PackageInfo} key={key}>
                <td className={style.GlobalProperty}>{globalProperty}</td>
                <td>{`${name}@${version}`}</td>
                <td>{loadDefault ? "Yes" : "No"}</td>
                <td>
                  <NPMLink className={style.Link} name={name} />
                </td>
                {isSaved ? null : (
                  <td>
                    {loading ? (
                      <span className={style.Spinner}></span>
                    ) : (
                      <button
                        onClick={() => removeDependency$.next(globalProperty)}
                        className={style.Remove}
                      >
                        <Remove />
                      </button>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
